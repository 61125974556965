//https://getbootstrap.com/docs/5.0/forms/form-control/
import React, { Component } from 'react';
class HBoxUpFileImg extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = { value: "" };

    }
    componentWillUpdate(nextProps, nextState) {
        this.data = nextProps;
    }
    render() {
        return (<div className="mb-3">
            {/* <label for="formFileLg" className="form-label"></label> */}
            <input
                className="form-control form-control-lg"
                name={this.data.id || "attachment"}
                id={this.data.id || "attachment"}
                onChange={this.data.btClick}
                type="file" />
        </div>)
    }
    /*
     <div className="mb-3">
                <label for="formFile" className="form-label">Default file input example</label>
                <input className="form-control" type="file" id="formFile" />
            </div>
            <div className="mb-3">
                <label for="formFileMultiple" className="form-label">Multiple files input example</label>
                <input className="form-control" type="file" id="formFileMultiple" multiple />
            </div>
            <div className="mb-3">
                <label for="formFileDisabled" className="form-label">Disabled file input example</label>
                <input className="form-control" type="file" id="formFileDisabled" disabled />
            </div>
            <div className="mb-3">
                <label for="formFileSm" className="form-label">Small file input example</label>
                <input className="form-control form-control-sm" id="formFileSm" type="file" />
            </div>*/

    render_v0 = () => {
        return (<div>
            <div className="input-group">
                <div className="custom-file">
                    <input type="file"
                        className="custom-file-input"
                        name={this.data.id || "attachment"}
                        id={this.data.id || "attachment"}
                        onChange={this.data.btClick}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Seleccione un Archivo</label>
                </div>
            </div>
        </div>

        );
    }

}
export default HBoxUpFileImg;