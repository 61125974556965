import Api  from "../modMaterial/ApiMaterial";
const _menu =
{
    name: 'Dashboard',
    arMenu: [
        {
            titulo: "Ramos", icon: "fa fa-bug"
            , menNiv: [{ titulo: "Activos", ruta: Api.cursosActListRoute, icon: "fa fa-bug" }
                , { titulo: "Desarrollo", ruta: Api.cursosDesListRoute, icon: "fa fa-bug" }
                , { titulo: "Futuro", ruta: Api.cursosFutListRoute, icon: "fa fa-bug" }]
        }
        , {
            titulo: "Material", icon: "fa fa-bug"
            , menNiv: [{ titulo: "Activos", ruta: Api.materialListActivosRoute, icon: "fa fa-bug" }
                , { titulo: "Desarrollo", ruta: Api.materialListDesarrolloRoute, icon: "fa fa-bug" }
                , { titulo: "Realizar", ruta: Api.materialListFuturoRoute, icon: "fa fa-bug" }]
        }
    ]
}

export default _menu
