import React ,{Component} from "react";
import { Route } from "react-router-dom";
import HMainRouter  from "global/components/Page/MenuLTE/HMainRouter";

import Api  from "../../modMaterial/ApiMaterial";

import CursosActivosList from "modMaterial/cursos/CursoActList";
import CursosActivosEdit from "modMaterial/cursos/CursoActEdit";
import CursosDesarrolloList from "modMaterial/cursos/CursoDesList";
import CursosFuturoList from "modMaterial/cursos/CursoFutList";

import CursosConteList from "modMaterial/cursos_conte/ConteList";

import MaterialView from "modMaterial/material/MaterialView";

import MaterialTreeListA from "modMaterial/material/MaterialTreeListA";
import MaterialTreeListB from "modMaterial/material/MaterialTreeListB";
import MaterialTreeListPdf from "modMaterial/material/MaterialTreeListHtml";
import MaterialTreeEdit from "modMaterial/material/MaterialTreeEdit";
import MaterialListFuturo from "modMaterial/material/MaterialListFuturo";
import MaterialListDesarrollo from "modMaterial/material/MaterialListDesarrollo";
import MaterialListActivos from "modMaterial/material/MaterialListActivos";
import MaterialTreeImageList from "modMaterial/material/MaterialTreeImgList";

class MainRouterMod  extends Component{
   render() {
      return (
         <HMainRouter>
            <Route exact path={Api.cursosActEditRoute} element={<CursosActivosEdit/>} />
            <Route exact path={Api.cursosActListRoute} element={<CursosActivosList/>} />
            <Route exact path={Api.cursosDesListRoute} element={<CursosDesarrolloList/>} />
            <Route exact path={Api.cursosFutListRoute} element={<CursosFuturoList/>} />
            <Route exact path={Api.cursosConteListRoute} element={<CursosConteList/>} />

            

            <Route exact path={Api.materialViewRoute} element={<MaterialView/>} />

            <Route exact path={Api.materialListActivosRoute} element={<MaterialListActivos/>} />
            <Route exact path={Api.materialListDesarrolloRoute} element={<MaterialListDesarrollo/>} />
            <Route exact path={Api.materialListFuturoRoute} element={<MaterialListFuturo/>} />
            
            <Route exact path={Api.materialTreeListARoute} element={<MaterialTreeListA/>} />
            <Route exact path={Api.materialTreeListBRoute} element={<MaterialTreeListB/>} />
            <Route exact path={Api.materialTreeListPdfRoute} element={<MaterialTreeListPdf/>} />
            <Route exact path={Api.materialTreeViewRoute} element={<MaterialView/>} />
            <Route exact path={Api.materialTreeEditRoute} element={<MaterialTreeEdit/>} />
            <Route exact path={Api.materialTreeImageRoute} element={<MaterialTreeImageList/>} />

         </HMainRouter>
         );
   }
}
export default MainRouterMod;




