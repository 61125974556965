import React from 'react';
import apiScript from "global/utils/funScript";
class HBoxHtmlCkEdHtml extends React.Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            textAreaValue: this.data.dataHtml
        };
        this.handleChange = this.handleChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }
    
    onInput(data) {
        console.log('onInput: ' + data);
    }
    componentDidMount = () => { 
        let stNameId = this.data.id
//        console.log("stNameId",stNameId)
        CKEDITOR.replace(stNameId);
    }
    getDataHtml = () => {
        let data = CKEDITOR.instances[this.data.id].getData();
        //console.log("Test Data",data)
        return data
    }
    hh(data){
        console.log("jajajajaj",data)
    }
    handleChange = (e) => {
        //const previousValue = e.previousValue;
        //const newValue = e.value;
        console.log("cambia",e.value)
        this.setState({
            textAreaValue: e.value
        });
    }
    render() {
        //const { valueContent, editorValueType } = this.state;
        //console.log(this.data.dataHtml)
        //defaultValue={this.data.dataHtml}
        return (<>
            <textarea cols="80" 
            ref={x => { this.txArea = x; }}
            onChange={this.handleChange}
            id={this.data.id}
            name={this.data.id} 
            rows="10">
            {this.state.textAreaValue}
            </textarea>
        </>
        );
    }

}

export default HBoxHtmlCkEdHtml;