import React, { Component } from "react";
import Api from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";

class MaterialView extends HFormUser {
    componentDidMount() {  this.readApi(this.props.location.state.detKey, 0); }
    readApi(keyPar, masmen) {
        this.setState({ loading: true })
        //console.log("MaterialView:",keyPar,masmen)
        this.post(Api.materialViewId, { idKey: keyPar, sumar: masmen, idRs: 0 })
            .then(data => {
                this.stateHarrys(this, data)
                apiRuta.toUpdateRutaLast(this, { detKey: data.rows[0].idmateria })
            })
    }

    btSiguiente = () => { this.readApi(this.state.data.idmateria, 1) }
    btAnterior = () => { this.readApi(this.state.data.idmateria, -1) }
    // btGrabar=()=>{
    //       	this.post(Api.materialViewIdSave,{idKey:this.state.data.idmateria})
    //           .then(data=>{this.stateHarrys(this,data)
    //         })     
    // }
    btEditar = () => {
        //console.log("Editar==>",this.state.data.idmateria,this.props.location.state.detKeyPadre)
        apiRuta.toRutaAdd(this, "Editar", Api.materialTreeEdit, {
            detKey: this.state.data.idmateria
            , detKeyPadre: this.props.location.state.detKeyPadre
        })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        return (<>
            {/* { false &&
    <div className="nav navbar navbar-expand navbar-white navbar-light border-bottom p-0">
      <a className="nav-link bg-light" onClick={this.btAnterior} data-widget="iframe-scrollleft">
        <i className="fas fa-lg fa-angle-double-left"></i></a>
      <ul className="navbar-nav overflow-hidden" role="tablist"></ul>
      <ul className="navbar-nav overflow-hidden" role="tablist"></ul>
      <a className="nav-link bg-light" onClick={this.btSiguiente} data-widget="iframe-scrollright">
            <i className="fas fa-lg fa-angle-double-right"></i></a>
      <a className="nav-link bg-light" onClick={this.btnEditData} data-widget="iframe-scrollright">
          <i className="far fa-lg fa-edit"></i></a>
    </div>
  } */}


            <ContainerRightEdit key="MatView"
                contexto={this}
                msgErr={this.state.resObj}
                styleCol12={{ width: '100%', height: '100%' }}
                tituloContainer={this.state.data && this.state.data.descripcion}

                bt1Icon="fas fa-lg fa-angle-double-left"
                bt1Click={this.btAnterior}
                bt1Class="btn btn-default btn-xs"

                bt2Icon="fa fa-file-pdf"
                bt2Click={this.btEditar}
                bt2Class="btn btn-default btn-xs"

                bt3Icon="fas fa-lg fa-angle-double-right"
                bt3Click={this.btSiguiente}
                bt3Class="btn btn-default btn-xs"


                barraView={false}
            >




                {/*<div className="tab-content">
      <div className="tab-empty">
        <h2 className="display-4">No tab selected!</h2>
      </div>
      <div className="tab-loading">
        <div>
          <h2 className="display-4">Tab is loading <i className="fa fa-sync fa-spin"></i></h2>
        </div>
      </div>
     </div>*/}

                <form className="form-horizontal well" id="forms-registro">
                    <fieldset>
                        {this.state.data && <div className="editor"
                            dangerouslySetInnerHTML={{ __html: this.state.data.observ }}
                            style={styleObj}>
                        </div>
                        }
                    </fieldset>
                </form>
            </ContainerRightEdit>

        </>

        );
    }

}
const styleObj = {
    padding: '10px 50px 30px 40px',
    //color: 'white',
    //backgroundColor: 'red'
};
/*
    <HBoxText  
                    disabled
                    label="id"
                id="txId" 
                title="Código" 
                value={this.state.data && this.state.data.idmateria}
                />		       
*/
export default WithNavigate(MaterialView);