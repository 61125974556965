import React, { Component } from "react";
import Api from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";



class MaterialEdit extends HFormUser {
    dataActiva = [{ name: 1, id: 1, descripcion: 'Activo' }
        , { name: 2, id: 2, descripcion: 'En Desarrollo' }
        , { name: 3, id: 3, descripcion: 'Por Realizar' }]

    constructor() {
        super();
        this.state = {
            data: [],
            loading: true,
        };
    }
    componentDidMount() { this.readApi(); }
    handleChange = name => event => { this.setState({ error: "" }); };
    readApi() {
        console.log("this.props.location.state.detKey", this.props.location.state.detRow)
        this.post(Api.cursosActEdit, { idKey: this.props.location.state.detRow.name })
            .then(data => { this.stateHarrys(this, data) })
        this.readCurTip()
    }

    actualizarApi() {
        var stData = {
            idKey: this.props.location.state.detRow.name
            , codigo: document.getElementById("txCodigo").value
            , descrip: document.getElementById("txDescrip").value
            , cbcurtip: document.getElementById("cbCurTip").value
            , cbetapa: document.getElementById("cbEtapa").value
        }
        this.post(Api.cursosActAct, stData).then(data => { if (data.ok) this.btVolver() })
    }
    btVolver = () => { apiRuta.toRutaBack(this) }

    readCurTip() {
        this.post(Api.cursosTipList
            , {//idKey:this.props.location.state.detSistema
                //,idSistema:this.props.location.state.detSistema
            })
            .then(data => { this.setState({ dataCurTip: data.rows }) })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        let stActLabel = "Activar"
        if (this.state.data.swactiva == 1) stActLabel = "Desactivar"
        return (<ContainerRightEdit
            tituloContainer={this.state.data && ('Código : ' + this.state.data.codigo)}
            bt1={this.btVolver}
            bt1Icon="fas fa-backward"
            contexto={this}
        >
            <HBoxForm>
                <HBoxText disabled
                    label="id"
                    id="txId"
                    title="Código"
                    value={this.state.data && this.state.data.idcurso}
                />
                <HBoxText
                    label="Código"
                    id="txCodigo"
                    title="Código del Objeto"
                    style={{ width: '120px' }}
                    maxLength="20"
                    onClickHelp={this.helpRamo}
                    value={this.state.data && this.state.data.codigo}
                />
                <HBoxText
                    label="Título"
                    id="txDescrip"
                    title="Título"
                    value={this.state.data && this.state.data.descripcion}
                    maxLength="100"
                    size="50"
                />
                <HBoxSelect
                    label="Tipo Item"
                    id="cbCurTip"
                    title="Tipo de Objeto "
                    value={this.state.data && this.state.data.idcurtip}
                    option={this.state.dataCurTip}
                />
                <HBoxSelect
                    label="Etapa"
                    id="cbEtapa"
                    title="Estado del Item "
                    value={this.state.data && this.state.data.swactiva}
                    option={this.dataActiva}
                />
                <HBoxButtonCrud
                    btActualizarClick={() => this.actualizarApi()}
                />
            </HBoxForm>
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(MaterialEdit);