import React, { Component  } from "react";
import Api  from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";
class MaterialActList extends HFormUser {
    componentDidMount() {this.readApi();} 
    readApi = ()=>{
        this.post(Api.cursosConteList,{idKey:this.props.location.state.detKey})
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    startSearch = bus=>{ this.readApi()} 
    startRefresh = i => {this.readApi();};
    startCrear= idReg => {this.edit(-1)};
    editTree = row => {
      apiRuta.toRutaAdd(this,"Tree",Api.materialTreeListARoute,{ detKey: row.name,detRow:row })
    };    
    helpMateConte = (obj) => {
        this.setState({loading:true})
        document.getElementById("txIdConte").value = obj.idmateria +"/"+ obj.codigo +"/"+ obj.descripcion
        this.post(Api.cursosConteAdd,{idCurso:this.props.location.state.detKey,idMateria:obj.idmateria})
          .then(data=>{this.stateHarrys(this,data)})    
    }
    btDelete= (row) => {
      this.setState({loading:true})
      this.post(Api.cursosConteDel,{idCurso:this.props.location.state.detKey,idMateria:row.idmateria})
      .then(data=>{this.stateHarrys(this,data)})    
    }
	  render() { 
	    if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                   { id:"codigo" , label:"Sigla"   ,align:"left",swFiltro:false,style:{width:"100px"}}
                 , { id:"descripcion" , label:"Descripción"    ,swFiltro:false}
                 , { id:"bt_Del", label:"Elim.",align:"center",accion:this.btDelete,clsIcono:"fa fa-trash-alt",clsBoton:"btn btn-danger"}
                 , { id:"bt_EditTree", label:"HTML",align:"center",accion:this.editTree,clsIcono:"fa fa-sitemap",clsBoton:"btn btn-success"}
                ]    
 		return (<ContainerRightTable 
                            tituloContainer='Contenido del Curso'
                            titulos={titulos}
                            resObj={this.state.resObj}
                            contexto={this}
      
                            > 
                <HBoxTextCod
                        label="Agregar Contenido"
                        id="txIdConte" 
                        ref={x=>this.txIdMas=x}
                        maxLength="0"
                        value={this.state.data && this.state.data.detpadre}
                        valueid={this.state.data && this.state.data.detpadre}

                        sqlHelp="hlp_mate_conte"
                        onClickHelp={this.helpMateConte}                
                        />                             
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(MaterialActList);