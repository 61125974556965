import React, { Component } from "react";
import Api from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxHtmlCkEd from "global/components/Page/InputHtml/HBoxHtmlEdit/HBoxHtmlCkEd";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";
import HBoxTextFecha from "global/components/Page/InputHtml/HBoxTextFecha";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";

class MaterialEdit extends HFormUser {
    dataActiva = [{ name: 1, id: 1, descripcion: 'Activo' }
        , { name: 2, id: 2, descripcion: 'En Desarrollo' }
        , { name: 3, id: 3, descripcion: 'Por Realizar' }]
    componentDidMount() {

        //console.log("detkey*************",this.props.location.state.detKey)
        this.readApi(this.props.location.state.detKey, 0);
        this.readTipo();
    }
    //shandleChange = name => event => {this.setState({ error: "" });    };    
    readApi(keyPar, masmen) {
        this.setState({ loading: true })
        this.post(Api.materialViewId, { idKey: keyPar, sumar: 99999, idRs: 0 })
            .then(data => {//console.log("data",data)
                this.stateHarrys(this, data)
            })
            .catch(error => { console.log("err", error) })

    }
    readTipo() {
        this.post(Api.materialTipList)
            .then(data => { this.setState({ dataMatTip: data.rows }) })
    }

    //btSiguiente=()=>{this.readApi(this.state.data.idmateria,1)}
    //btAnterior=()=>{this.readApi(this.state.data.idmateria,-1)}
    btGrabar = () => {
        //console.log("imga",this.txImgUp.getImg64(),this.txImgDown.getImg64())
        //console.log("this.childHtml",this.childHtml)
        var stData = {
            idKey: this.state.data.idmateria
            , data: this.childHtml.getDataHtml()
            , cbmattip: document.getElementById("cbMatTip").value
            // , imgUp: this.txImgUp.getImg64()
            // , imgDown: this.txImgDown.getImg64()

            , codigo: document.getElementById("txCodigo").value
            , secuencia: document.getElementById("txSec").value
            , descrip: document.getElementById("txDescrip").value
            , swactivo: document.getElementById("cbEtapa").value
            , id_padre: this.txIdPadre.getId()
            , cbmattip: document.getElementById("cbMatTip").value

        }
        this.post(Api.materialTreeSave, stData)
            .then(data => {//this.stateHarrys(this,data)
                //if(data.ok) this.readApi(this.state.data.idmateria,1)
                if (data.ok) apiRuta.toRutaBack(this)
            })
        //console.log("stData",stData)
    }
    btImage = () => {
        apiRuta.toRutaAdd(this, "Imagen", Api.materialTreeImageRoute, {
            detKey: this.state.data.idmateria
            , detKeyPadre: this.props.location.state.detKeyPadre
        })
    }
    helpMatePadre = (obj) => {
        document.getElementById("txIdPadre").value = obj.idmateria + "/" + obj.codigo + "/" + obj.descripcion
        obj.valueid = obj.idmateria
    }
    render() {
        if (this.state.loading) return (<ShowLoading />)
        /*
                    bt1={this.btAnterior}
                    bt1Icon='fas fa-lg fa-angle-double-left'
                    bt3={this.btSiguiente}
                    bt3Icon='fas fa-lg fa-angle-double-right'
        */
        return (<>
            <ContainerRightEdit
                contexto={this}
                msgErr={this.state.resObj}
                tituloContainer={this.state.data && this.state.data.descripcion}
                
                bt1Click={() => this.btGrabar()}
                bt1Icon='fas fa-save'

                bt2Icon="far fa-lg fa-image"
                bt2Click={() => this.btImage()}
                bt2Class="btn btn-default btn-xs"

                barraView={false}
            >
                <HBoxText
                    label="Código"
                    id="txCodigo"
                    title="Código del Objeto"
                    style={{ width: '120px' }}
                    maxLength="20"
                    value={this.state.data && this.state.data.codigo}
                />

                <HBoxText
                    label="Título"
                    id="txDescrip"
                    maxLength="100"
                    value={this.state.data && this.state.data.descripcion}
                />


                <HBoxHtmlCkEd
                    id="txEditCk"
                    ref={x => { this.childHtml = x; }}
                    dataHtml={this.state.data && this.state.data.observ}
                    valueChanged={this.valueChanged}
                />
                <br />

                <HBoxSelect
                    label="Etapa"
                    id="cbEtapa"
                    title="Estado del Item "
                    value={this.state.data && (this.state.data.swactiva || 1)}
                    option={this.dataActiva}
                />
                <HBoxSelect
                    label="Tipo Item"
                    id="cbMatTip"
                    title="Tipo de Objeto "
                    value={this.state.data && (this.state.data.idmattip || 1)}
                    option={this.state.dataMatTip}
                />

                <HBoxTextNum
                    label="Secuencia"
                    id="txSec"
                    value={this.state.data && (this.state.data.secuencia || -1)}
                    minLength="1"
                    maxLength="4"
                    size="4"
                    style={{ width: '70px' }}
                />


                <HBoxTextFecha
                    label="F.Activación"
                    id="txFActiva"
                    title="Fecha Activación"
                    value={this.state.data && this.state.data.factivacion}
                    maxLength="10"
                    style={{ width: "120px" }}
                />
                <HBoxTextFecha
                    label="F.Desactivación"
                    id="txFDesactiva"
                    title="Fecha Desactivación"
                    value={this.state.data && this.state.data.fdesactivacion}
                    maxLength="10"
                    style={{ width: "120px" }}
                />
                <HBoxTextFecha
                    label="F.Creado"
                    id="txFCreado"
                    title="Fecha Creación"
                    disabled
                    value={this.state.data && this.state.data.finserta}
                    maxLength="10"
                    style={{ width: "120px" }}
                />

                <HBoxTextCod disabled
                    label="Padre"
                    id="txIdPadre"
                    visibility={true}
                    ref={x => this.txIdPadre = x}
                    maxLength="15"
                    title="Padre"
                    value={this.state.data && this.state.data.detpadre || this.props.location.state.detKeyPadre}
                    valueid={this.state.data && this.state.data.detpadre || this.props.location.state.detKeyPadre}

                    sqlHelp="hlp_mate_padre"
                    onClickHelp={this.helpMatePadre}
                />
                <HBoxText disabled
                    label="id"
                    id="txId"
                    visibility={true}
                    maxLength="15"
                    title="Código"
                    value={this.state.data && this.state.data.idmateria || this.props.location.state.idmateria}
                />
                {/*<HBoxHtmlEdit  
              idXX="txEdit"
              ref={instance => { this.childHtml = instance; }}
              dataHtml={this.state.data && this.state.data.observ}
              valueChanged={this.valueChanged}
     />	*/}



            </ContainerRightEdit>
        </>
        );
    }

}
export default WithNavigate(MaterialEdit);