const cursosActEdit='/cursos/cursosactedit'
const cursosActAct ='/cursos/cursosactact'
const cursosActActivar ='/cursos/cursosactactivar'
const cursosActEditRoute='/cursos/cursosactedit'
const cursosTipList='/cursos/cursostiplist'

const cursosActList='/cursos/cursosactlist'
const cursosActListRoute='/cursos/cursosactlist'
const cursosDesListRoute='/cursos/cursosdeslist'
const cursosFutListRoute='/cursos/cursosfutlist'
const cursosConteList='/cursos/cursoscontelist'
const cursosConteAdd='/cursos/cursosconteadd'
const cursosConteDel='/cursos/cursoscontedel'
const cursosConteListRoute='/cursos/cursoscontelist'
const materialTipList='/material/materialtiplist'

const materialListActivosRoute='/material/activoslist'
const materialListDesarrolloRoute='/material/desarrollolist'
const materialListFuturoRoute='/material/porhacerlist'

const materialList='/material/activoslist'
//const materialEdit='/material/activosedit'
const materialEditUp='/material/activoseditup'
const materialEditUpNiv='/material/activoseditupniv'
const materialEditDownNiv='/material/activoseditdownniv'
const materialEditDrag='/material/activoseditdrag'
const materialEditDown='/material/activoseditdown'
const materialEditNivel='/material/activoseditnivel'
//const materialAct='/material/activosact'
//const materialEditRoute='/material/edit'
const materialTreeEdit='/material/treeedit'
const materialTreeEditRoute='/material/treeedit'
const materialTreeImageRoute='/material/treeimage'
const materialTreeImage='/material/treeimage'
const materialTreeImageLoad="/material/treeimagefileload"
const materialTreeImageRemove="/material/treeimagefileremove"
const materialTreeAct='/material/materialact'
const materialTreeListPdfRoute='/material/treelisthtml'
const materialTreeListARoute='/material/treelistA'
const materialTreeListBRoute='/material/treelistB'
const materialTreeLoad='/material/materialtreeload'
const materialTreeViewRoute='/material/treeview'
const materialViewId='/material/materialviewid'
const materialViewIdSave='/material/materialviewidsave'
const materialViewRoute='/material/view'
const materialTreeSave='/material/materialtreesave'


module.exports = {
  
  materialViewId,
  materialViewIdSave,
  materialTipList,

  materialListActivosRoute,
  materialListDesarrolloRoute,
  materialListFuturoRoute,
  materialList,
  // materialEdit,
  // materialAct,
  //materialEditRoute,
  materialTreeListPdfRoute,
  materialTreeListARoute,
  materialTreeListBRoute,
  materialEditDrag,
  materialEditUp,
  materialEditUpNiv,
  materialEditDownNiv,
  materialEditDown,
  materialEditNivel,
  materialTreeEdit,
  materialTreeEditRoute,
  materialTreeImageRoute,
  materialTreeImage,
  materialTreeImageLoad,
  materialTreeImageRemove,
    materialTreeAct,
  materialTreeLoad,
  materialTreeViewRoute,
  materialTreeSave,
  materialViewRoute,

  cursosActListRoute,
  cursosActList,

  cursosDesListRoute,
  cursosFutListRoute,
  
  cursosConteListRoute,
  cursosConteList,
  cursosConteAdd,
  cursosConteDel,

  cursosActEditRoute,
  cursosActEdit,
  cursosActAct,
  cursosActActivar,
  cursosTipList,

}
