import React, { Component } from "react";
import HBoxUpFileImg from "global/components/Page/InputHtml/HUpLoadFile/HBoxUpFileImg";


import Api from "../ApiMaterial";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";

import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";

class MaterialTreeImgList extends HFormUser {
    objPar = null
    constructor(objPar) {
        //console.log("Material List *******************")
        super();
        this.objPar = objPar
        console.log("Constructor", this)
    }
    componentDidMount() {  this.readApi(); }
    readApi = () => {
        this.setState({ loading: true })
        this.post(Api.materialTreeImage, { idKey: this.props.location.state.detKey })
            .then(data => { this.stateHarrys(this, data) })
    }
    // startRefresh = i => { this.readApi(); };

    fileUp = () => {
        this.setState({ loading: true })
        this.upLoadFile("imgFileUp", Api.materialTreeImageLoad
                , { idMateria: this.props.location.state.detKey, idImg: 0 })
            .then(data => { this.readApi() })
    }

    
    // fileUpBorrar = () => {
    //     this.setState({ loading: true })
    //     // console.log("Subiendo Imagen", this.props.location.state.detKey, this.btImgUp.getImg64())
    //     const aa = this.upLoadImg("imgFileUp", Api.materialTreeImageLoad
    //         , { idMateria: this.props.location.state.detKey, idImg: 0 })
    //         .then(data => { this.readApi() })
    // }
    fileUpdate = (row, index) => {
        this.setState({ loading: true })
        console.log("File Up", index, row.idimg, row)
        const stFileObj = "file-input_" + index
        //console.log("FileSt",document.getElementById(stFileObj).value)
        this.upLoadFile(stFileObj, Api.materialTreeImageLoad
            , { idMateria: this.props.location.state.detKey, idImg: row.idimg })
            .then(data => { this.readApi() })
    }
    fileRemove = (row, index) => {
        this.setState({ loading: true })
        this.post(Api.materialTreeImageRemove, { idMateria: this.props.location.state.detKey, idImg: row.idimg })
            .then(data => { this.readApi() })
    }


    render() {
        // console.log("Data",this.usuarioVar)
        if (this.state.loading) return (<ShowLoading />)
        console.log("image", this.state.resObj)
        const titulos = [
            { id: "nrologico", label: "nombre", align: "left", swFiltro: false }
            //, { id:"nombrelogico" , label:"Lógico"   ,align:"left"}
            , { id: "nombrearchivo", label: "Archivo", align: "left", swFiltro: false }
            , { id: "img_imagen", label: "Img", align: "center", swFiltro: false }
            , { id: "upl_FileUp", label: "Modif.", align: "center", accion: this.fileUpdate, clsIcono: "fas fa-file-upload", swFiltro: false }
            , { id: "bt_Remove", label: "Remove", align: "center", accion: this.fileRemove, clsIcono: "fas fa-trash-alt" }
        ]
        return (<ContainerRightTable key="Material_List"
            tituloContainer={"Imágenes" + this.props.location.state.detKey}
            titulos={titulos}
            resObj={this.state.resObj}


            // bt6Click={this.startRefresh}
            // bt6Icon="fa fa-sync"
            // bt6Class="btn-danger"


            contexto={this}
        >
            <HBoxUpFileImg 
                    id="imgFileUp" 
                    ref={xx => this.btImgUp = xx} 
                    btClick={() => this.fileUp()} />
        </ContainerRightTable>
        );
    }

}

export default WithNavigate(MaterialTreeImgList);