import React from 'react';
import HBoxHtmlCkEditor from './HBoxHtmlCkEdHtml';
class HBoxHtmlCkEd extends React.Component {
    getDataHtml = () => {
        //console.log("this.data.dataHtml",this.data.dataHtml)
        //return document.getElementById(this.props.id).value
        return this.texyArea.getDataHtml()
    }
    render = () => {
        return (
            <HBoxHtmlCkEditor
                ref={x => { this.texyArea = x; }}
                {...this.props}
            />
        );
    }
}

export default HBoxHtmlCkEd;