import React, { Component } from "react";
import Api from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CursosActListPad extends HFormUser {
    constructor(stEstado) {
        super();
        this.stEstado = stEstado

    }
    componentDidMount() { this.readApi([]); }
    readApi = (stParamDict) => {
        this.post(Api.cursosActList, { estado: this.stEstado, qryFiltro: stParamDict })
            .then(data => { this.stateHarrys(this, data) })
    }
    startCrear = idReg => { this.btEdit({ name: -1 }) };
    btConte = row => {
        apiRuta.toRutaAdd(this, "Contenidos", Api.cursosConteListRoute, { detKey: row.name })
    };
    btEdit = row => {
        apiRuta.toRutaAdd(this, "EditCurso", Api.cursosActEditRoute, { detRow: row })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "codigo", label: "Sigla", align: "left", style: { width: "120px" } }
            , { id: "descripcion", label: "Descripción", align: "left", swFiltro: true }
            , { id: "hijos", label: "N.Item", align: "right", style: { width: "70px", align: "right" } }
            , { id: "tipcur", label: "Tipo", align: "left" }
            , { id: "bt_Edit", label: "Edit", align: "center", accion: this.btEdit, clsIcono: "fa fa-edit" }
            , { id: "bt_Conte", label: "Conte", align: "center", accion: this.btConte, clsIcono: "fa fa-sitemap" }
        ]
        return (<ContainerRightTable
            tituloContainer='Cursos'
            titulos={titulos}
            resObj={this.state.resObj}
            bt1Click={this.startCrear}
            bt1Icon="fas fa-plus"
            bt1Class="btn-danger"
            btSearchClick={this.startSearch}
            contexto={this}
        >
        </ContainerRightTable>
        );
    }

}
export default CursosActListPad;