const apiScript = {

  cargarJs(stFileJs) {
    console.log("cargarJs",stFileJs)
    const script = document.createElement("script");
    script.src = stFileJs;
    script.async = true;
    document.body.appendChild(script);
  },
  loadScriptHead(src) {
    console.log("loadScriptHead",src)
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", src);
    head.appendChild(script);
  }
}

export default apiScript;
/*

    componentDidMount00() {
    this.loadScript('//cdnjs.com/some/library11.js')
    this.loadScript('//cdnjs.com/some/other/library22.js')
  }
  */
