import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiMaterial";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";

class MaterialTreeListHtml extends HFormUser {
    componentDidMount() {  this.readApi(); }
    readApi() {
        this.setState({ loading: true });
        this.post(Api.materialTreeLoad, { idKey: this.props.location.state.detKey, all: 1 })
            .then(data => {
                this.stateHarrys(this, data)
            })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        console.log(this.state.resObj)
        return (<ContainerRightEdit key="right_edit"
            tituloContainer={"Material Html"}
            contexto={this}
        >
            {this.state.resObj.rows.map((item, index) => {
                return (
                    <div className="col-12">
                        <div className="editor"
                            dangerouslySetInnerHTML={{ __html: item.html_observ }}
                        >
                        </div>
                    </div>
                );
            })}


        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(MaterialTreeListHtml);