//$$HARRYSI64$2660$$90%$$50%$$
import React, { Component } from "react";
import Api from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";

class MaterialActList extends HFormUser {
    objPar = null
    constructor(objPar) {
        super();
        this.objPar = objPar
    }
    componentDidMount() { this.readApi([]); }
    readApi = (stParamDict) => {
        this.post(Api.materialList, { opcion: this.objPar.opcion, qryFiltro: stParamDict })
            .then(data => { this.stateHarrys(this, data) })
    }
    startCrear = idReg => { this.edit({ idmateria: -1 }) };
    edit = row => {
        //console.log("rowlist", row)
        apiRuta.toRutaAdd(this, "Editar", Api.materialTreeEditRoute, { detKey: row.idmateria, detKeyPadre: -1 })
    };
    editTree = row => {
        //console.log("row",row,this.objPar)
        apiRuta.toRutaAdd(this, this.objPar.titulo, Api.materialTreeListARoute
            , {
                detKey: row.name
                //,detObjPar:this.objPar
                , detRow: row
            })
    };
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
    };

    render() {
        // console.log("Data",this.usuarioVar)
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "codigo", label: "Sigla", align: "left" }
            //, { id:"idmateria" , label:"Id"   }
            , { id: "descripcion", label: "Descripción", align: "left" }
            , { id: "tip_descrip", label: "Tipo", align: "left", swFiltro: false }
            //, { id:"ch_act" , label:"Activo"    ,swFiltro:false}
            //, { id:"nitem" , label:"NIem"    ,swFiltro:false}
            //, { id:"idmateria" , label:"Id"    ,swFiltro:false}
            , { id: "bt_Edit", label: "Ed", align: "center", accion: this.edit, clsIcono: "fa fa-edit" }
            , { id: "bt_EditTree", label: "Tree", align: "center", accion: this.editTree, clsIcono: "fa fa-sitemap" }
        ]
        return (<><ContainerRightTable key="Material_List"
            tituloContainer={this.objPar.titulo}
            titulos={titulos}
            resObj={this.state.resObj}
            bt1Click={this.startCrear}
            bt1Icon="fas fa-plus"
            bt1Class="btn-danger"
            contexto={this}
        >
        </ContainerRightTable>
        </>
        );
    }

}

export default MaterialActList;