import React, { Component } from "react";
import Api from "../ApiMaterial";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxTree from "global/components/Page/InputHtml/HBoxTree/HBoxTree";

class MaterialTreeListPadre extends HFormUser {
    constructor(props, stPagAB) {
        super(props);
        this.stPagAB = stPagAB;
        this.swHtml = false;
    }
    componentDidMount() { this.readApi(); }
    readApi() {
        // console.log("padre",this.props.location.state.detKey
        //                ,this.props.location.state.detRow
        //                ,this.props.location.state.detObjPar)
        this.setState({ loading: true });
        this.post(Api.materialTreeLoad, { idKey: this.props.location.state.detKey })
            .then(data => {
                this.stateHarrys(this, data)
            })
    }

    btnClickEdit = (row) => {
        //    apiRuta.toRutaAdd(this, "EdTree", Api.materialEditRoute, { detRow: { name: row.id, detKeyPadre: this.props.location.state.detKey } })
        apiRuta.toRutaAdd(this, "Editar", Api.materialTreeEditRoute, {
            detKey: row.id
            , detKeyPadre: this.props.location.state.detKey
        })

    }
    btnClickHtml = (row) => {
        apiRuta.toRutaAdd(this, "EdTree", Api.materialTreeViewRoute
            , {
                detKey: row.id
                , detKeyPadre: this.props.location.state.detKey
                , detObjPar: this.props.location.state.objPar
            })
    }
    btnClickUp = (row) => {
        this.post(Api.materialEditUp, { idKey: row.id })
            .then(data => { this.readApi() })
    }
    btnClickDown = (row) => {
        this.post(Api.materialEditDown, { idKey: row.id })
            .then(data => { this.readApi() })
    }
    btnClickNivUp = (row) => {
        this.post(Api.materialEditUpNiv, { idKey: row.id })
            .then(data => { this.readApi() })
    }
    btnClickNivDown = (row) => {
        this.post(Api.materialEditDownNiv, { idKey: row.id })
            .then(data => { this.readApi() })
    }
    btnClickChildEdit = (row) => {
        apiRuta.toRutaAdd(this, "EdTree", Api.materialTreeEditRoute, { detKey: -1, detKeyPadre: row.id })
    }
    btnClickChild = (row) => {
        if (this.stPagAB == "A")
            apiRuta.toRutaAdd(this, "EdTree", Api.materialTreeListBRoute, { detKey: row.id, detRow: row })
        else
            apiRuta.toRutaAdd(this, "EdTree", Api.materialTreeListARoute, { detKey: row.id, detRow: row })
    }
    btnAddTree = () => {
        this.btnClickChildEdit({ id: this.props.location.state.detRow.idmateria })
    }
    btnPdf = () => {
        apiRuta.toRutaAdd(this, "Pdf", Api.materialTreeListPdfRoute, { detKey: this.props.location.state.detKey })
    }
    btnSwHtml = () => {
        this.swHtml = !this.swHtml
        this.readApi()
        //this.setState({ loading : false })
        //this.setState({ loading : true })
    }

    getTitulos = (swtml) => {
        let arrTit = []
        arrTit.push({ col: 'descripcion', label: 'Titulo' })
        if (swtml) arrTit.push({ col: 'html_observ', label: 'Observ' })
        if (swtml) arrTit.push({ col: 'observ', label: 'Observ' })
        //,{col:'btnClickView',label:'Visualizar',accion:this.btnClickView}
        // ,{col:'idmate',label:'Id'}
        arrTit.push({ col: 'btnClickEdit', label: 'Ed', accion: this.btnClickEdit, width: 60, clsIcono: 'fa fa-edit', clsBoton: 'btn btn-default btn-xs' })
        arrTit.push({ col: 'btnClickHtml', label: 'Html', accion: this.btnClickHtml, width: 60, clsIcono: 'fa fa-id-card', clsBoton: 'btn btn-default btn-xs' })
        arrTit.push({ col: 'btnClickHijo', label: 'HH', accion: this.btnClickChildEdit, width: 60, clsIcono: 'fa fa-plus', clsBoton: 'btn btn-default btn-xs' })
        //,{col:'btnClickDown',label:'Down',accion:this.btnClickDown,width:60,clsIcono:'far fa-hand-point-down',clsBoton:'btn btn-default btn-xs' }
        //,{col:'btnClickUp',label:'Up',accion:this.btnClickUp,width:60,clsIcono:'far fa-hand-point-up',clsBoton:'btn btn-default btn-xs' }
        //,{col:'btnClickUpNiv',label:'Niv+',accion:this.btnClickNivUp,width:60,clsIcono:'far fa-hand-point-left',clsBoton:'btn btn-default btn-xs' }
        // arrTit.push({ col: 'btnClickUpNiv', label: 'Niv-', accion: this.btnClickNivDown, width: 60, clsIcono: 'fa fa-minus', clsBoton: 'btn btn-default btn-xs' })
        arrTit.push({ col: 'btnChild', label: 'Hijos', accion: this.btnClickChild, width: 60, clsIcono: 'fa fa-sitemap', clsBoton: 'btn btn-default btn-xs' })
        return arrTit
    }
    dragLine = (e, icol) => {
        let visibleRows = e.component.getVisibleRows(),
            //sourceNode = e.component.getNodeByKey(e.itemData.id),
            targetNode = visibleRows[e.toIndex].node;
        //console.log("icol:",icol,e.itemData.fromIndex,e.itemData.toIndex,e.itemData,targetNode)
        this.post(Api.materialEditDrag, { idSrc: e.itemData.id, idTrg: targetNode.data.id })
            .then(data => { this.readApi() })

    }
    render() {
        if (this.state.loading) return (<ShowLoading />)
        //console.log("padre",this.props.location.state.detRow)
        return (<ContainerRightEdit key="right_edit"
            tituloContainer={this.props.location.state.detRow.descripcion}
            bt1Click={this.btnAddTree}
            bt1Icon="fa fa-plus"
            bt2Click={this.btnSwHtml}
            bt2Icon="fab fa-file-code"
            bt3Click={this.btnPdf}
            bt3Icon="fab fa-file-alt"
            contexto={this}
        >
            <HBoxTree key="KeyMat897"
                dataTree={this.state.resObj}
                dataTitulos={this.getTitulos(this.swHtml)}
                expand={false}
                dragLine={this.dragLine}
            />
        </ContainerRightEdit>
        );
    }

}
export default MaterialTreeListPadre;